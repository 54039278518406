@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Raleway:wght@200;400&display=swap);

/* ----------- GENERAL ------------- */

:root {
  --main-bg: #20232a;
  --main-opacity-header-mobile-bg: rgba(32, 35, 42, 0);
  --main-opacity-mobile-bg: rgba(40, 44, 52, 0.9);
  --modal-icon-bg: rgba(0, 0, 0, 0.2);

  --main-text-color: #dcdcdc;
  --focus-color: #64df15;
  --btn-bg-color: #4eb110;
  --btn-text-color: #dcdcdc;
}


:root:has(#home.lightMode) {
  --main-bg: #D3D7DE;
  --main-text-color: #242424;
  --focus-color: #CB3B48;
  --btn-bg-color: #CB3B48;
  --btn-text-color: #dcdcdc;
}


body {
  margin: 0;
  padding: 0;
  font-size: calc(0.9rem + 0.9vw);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #20232a;
  background-color: var(--main-bg);
  color: #dcdcdc;
  color: var(--main-text-color);
}

strong {
  font-weight: unset;
}

em {
  font-style: unset;
}

#darkModeIcon {
  position: fixed;
  top: 1rem;
  left: 1rem;
  width: clamp(1.8rem, 2vw, 2.3rem);
  cursor: pointer;
}

section {
  width: 55%;
  margin: 7rem 30rem;
  margin: 7rem auto;
}

.big-title {
  font-size: calc(1.3rem + 1vw);
  text-align: center;
  margin: 3rem 0;
}

.big-title::before {
  content: '[ ';
  color: #64df15;
  color: var(--focus-color);
}
.big-title::after {
  content: ' ]';
  color: #64df15;
  color: var(--focus-color);
}



/* ---------- NAVBAR ------------  */

.App-header {
  margin-top: 5rem;
  position: fixed;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
}

a.link {
  display: block;
  margin: 7rem 1rem;
  text-decoration: none;
  cursor: pointer;
}

a.link:hover {
  color:#64df15;
  color:var(--focus-color);
}

a.link.active {
  color: #64df15;
  color: var(--focus-color);
}

a.link.active::before {
  content: "<"
}

a.link.active::after {
  content: ">"
}

/* ----------- PROFIL COMPONENT ------------- */


.profile-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20rem;
}

.profile-title.mobile {
  display: none;
}


.profile-card {
  width: 48%;
}

.profile-card {
  -webkit-animation: slideFromLeft 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          animation: slideFromLeft 1.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}


@-webkit-keyframes slideFromLeft {
	0% {
		margin-left: -500px;
	}
  50% {
    margin-left: 50px;
  }
  100% {
    margin-left: 0;
  }
}


@keyframes slideFromLeft {
	0% {
		margin-left: -500px;
	}
  50% {
    margin-left: 50px;
  }
  100% {
    margin-left: 0;
  }
}

.profile-title {
  margin-bottom: 2rem;
  font-size: calc(1.2rem + 1vw);
  font-family: 'Raleway', sans-serif;
  color: #64df15;
  color: var(--focus-color);
}

.profile-title.mobile {
  display: none;
}

.profile-txt {
  font-size: calc(0.5rem + 0.7vw);
}

.profile-box img {
  width: 14rem;
  border-radius: 3px;
  box-shadow: 3px 2px 3px 1px #64df15;
  box-shadow: 3px 2px 3px 1px var(--focus-color);
  -webkit-animation: float 2s ease-in-out infinite;
          animation: float 2s ease-in-out infinite;
}

@-webkit-keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}

#profile p {
  margin-top: 0;
}

/* ----------- STACK COMPONENT ------------- */

.stack {
  text-align: center;
}

.icon {
  margin: 2rem;
  font-size: 4rem;
}

.icon:nth-child(1) {
  color: #e54c21;
}

.icon:nth-child(2) {
  color: #27a4d8;
}

.icon:nth-child(3) {
  color: #cf649a;
}
.icon:nth-child(4) {
  color: #f7e018;
}

.icon:nth-child(5) {
  color: #5ed3f3;
}

/* .icon:nth-child(6) {
  color: #8bbf3d;
} */


/* ----------- PROJECT COMPONENT ------------- */


/* #project img {
  display: block;
  margin: 0 auto;
  width: 80%;
} */


  #project article {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 7rem;
  }
  
  .project-card {
    width: 45rem;
    height: 18rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 10%;
  }

  .project-info {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: #e3e3e3;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    letter-spacing: 2px;
    font-size: 1.2rem;
    font-family: "Oswald", sans-serif;
    transition: 0.7s ease;
  }
  
  .project-info h2 {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 2.8rem;
  }

  .project-info p {
    word-spacing: 20px;

  }
  
  .project-info svg {
    cursor: pointer;
    color: #e3e3e3;
    margin: 1rem;
  }
  
  .project-info svg:hover {
    color: #64df15;
    color: var(--focus-color);
  }


  .project-card:hover .project-info {
    opacity: 1;
  }
  

/* ----------- CV COMPONENT ------------- */

.cv-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills {
  width: 30%;
  font-size: 1.05rem;
}

.downloadCV {
  display: block;
  margin-top: 3rem;
  padding: 0.6em 1em;
  font-size: 0.85em;
  background: #4eb110;
  background: var(--btn-bg-color);
  color: #dcdcdc;
  color: var(--btn-text-color);
  text-decoration: none;
  border-radius: 0.3em;
  border: none;
	-webkit-animation: pulse 1.5s ease-in-out infinite;
	        animation: pulse 1.5s ease-in-out infinite;
}

.downloadCV:hover {
  color: #4eb110;
  color: var(--btn-bg-color);
  background-color: #dcdcdc;
  background-color: var(--btn-text-color);
}

@-webkit-keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}


/* ----------- CONTACT  ------------- */

#contact {
  text-align: center;
  margin-top: 8rem;
  margin-bottom: 0;
}

#contact svg {
  font-size: 2.5rem;
  margin: 2.5rem;
  cursor: pointer;
  color: #dcdcdc;
  color: var(--main-text-color);
}

#contact svg:hover {
  color: #64df15;
  color: var(--focus-color);
  transform: scale(1.2);
}

#contact a {
  position: relative;
}

#contact a[href*="github"]:hover::after {
  content: "github.com/philippe76";
}

#contact a[href*="linkedin"]:hover::after {
  content: "in/philippe-lanougadere";
}

#contact a[href*="twitter"]:hover::after {
  content: "twitter.com/I_m_your_dev";
}

#contact a[href^="mail"]:hover::after {
  content: "p.lanougadere@gmail.com";
}

#contact a[href^="tel"]:hover::after {
  content: "06 60 80 37 66";
}

#contact a:hover::after {
  position: absolute;
  top: -8rem;
  left: -5rem;
  border-radius: 3%;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.2);
  background: var(--modal-icon-bg);
  color: #64df15;
  color: var(--focus-color);
  width: 15rem;
  font-size: 1.1rem;
  font-family: "Oswald", sans-serif;
}


/* ----------- FOOTER ------------- */


.footer-text {
  text-align: right;
  font-size: 0.9rem;
  margin-right: 2rem;
  word-spacing: 0.3rem;
}



/* ------------------------------------------ */
/* ----------- RESPONSIVE RULES ------------- */
/* ------------------------------------------ */

@media only screen and (max-width: 1220px) {
  /* ----------- MAIN PAGE ------------- */

  .box {
    max-width: 100%;
  }

  section {
    margin: 0 auto;
  }
  
  /* ----------- BURGER MENU ------------- */
  
  .App-header {
    border: none;
  }
  
  .bm-burger-button {
    position: fixed;
    width: 2.2rem;
    height: 2rem;
    right: 2rem;
    top: 5%;
  }
  
  .bm-burger-bars {
    background: #64df15;
    background: var(--focus-color);
    border-radius: 13%;

  }
  
  .bm-burger-button:hover .bm-burger-bars {
    background: #64df15;
    background: var(--focus-color);
  }
  
  .bm-cross-button {
    right: 2.5rem !important;
    top: 5% !important;
  }
  
  .bm-cross {
    background: #dcdcdc;
    background: var(--main-text-color);
    width: 0.5rem !important;
    height: 1.8rem !important;
  }
  
  .bm-cross-button:hover .bm-cross {
    background: #64df15;
    background: var(--focus-color);
  }
  
  .bm-item {
    display: inline-block;
    color: #dcdcdc;
    color: var(--main-text-color);
    margin-top: 2rem !important;
  }
  
  .bm-item-list {
    overflow-y: hidden !important;
  }
  
  .bm-menu {
    padding: 5em 1.3em 0;
    font-size: 1.15em;
  }
  
  .bm-menu-wrap {
    background-color: rgba(40, 44, 52, 0.9);
    background-color: var(--main-opacity-mobile-bg);
    top: 0;
  }
  
  /* ----------- PROFIL SECTION ------- */
  
  #profile {
    width: 100%;
    justify-content: center;
    margin: 0 auto;
  } 

  .profile-title {
    font-size: calc(1.4rem + 1vw);
  }

  .profile-title.desktop {
    display: none;
  }
  
  .profile-title.mobile {
    display: block;
    text-align: center;
  }

  .profile-txt {
    font-size: calc(0.7rem + 0.7vw);
  }
  
  
  #profile img {
    display: none;
  }
  
  .stack {
    margin: 2rem auto;
  }
  
  .stack .icon {
    margin: 0.5rem 1.5rem;
    font-size: 2.5rem;
  }
  
  
  /* ----------- PROJECT SECTION ------------- */
  
  
  
  /* ----------- CV SECTION ------------- */
  

  code .skills {
    font-size: 0.9rem;
  }
  

  /* ----------- CONTACT  ------------- */


  #contact {
    margin-top: 4rem;
  }
  
  #contact svg {
    font-size: 2rem;
    margin: 1.1rem;
  }

  #contact a[href]:hover::after {
    opacity: 0;
  }

}

